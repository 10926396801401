import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';

import PlayerRankingReportContainer from './player-ranking-report.container';
import { getAuthUser } from '../../../lib/services/auth.service';
import { getCompanySportTerminology } from '../../../lib/services/company.service';
import { pluralTerm } from '../../../lib/helpers/term.helper';
import { SportTerminology } from '../../../lib/types';

const GET_PLAYER_RANKINGS = gql`
  query GetPlayerRankings($assessmentId: ID!, $ageGroupId: ID!) {
    ageGroup(_id: $ageGroupId) {
      _id
      name
    }
    playerAssessments(assessmentId: $assessmentId, ageGroupIds: [$ageGroupId]) {
      _id
      level
      team
      attendanceCount
      player {
        _id
        code
        firstName
        lastName
      }
      position {
        name
      }
      contacts {
        name
        email
        phone
      }
      weightedScore
      adjustedScore
    }
  }
`;

const ASSIGN_LEVEL = gql`
  mutation AssignLevel($assessmentId: ID!, $playerAssessmentIds: [ID!]!, $level: String!) {
    assignLevel(assessmentId: $assessmentId, playerAssessmentIds: $playerAssessmentIds, level: $level) {
      _id
      level
    }
  }
`;

const ASSIGN_TEAM = gql`
  mutation AssignTeam($assessmentId: ID!, $playerAssessmentIds: [ID!]!, $team: String!) {
    assignTeam(assessmentId: $assessmentId, playerAssessmentIds: $playerAssessmentIds, team: $team) {
      _id
      team
    }
  }
`;

const CONCLUDE_PLAYERS = gql`
  mutation ConcludePlayers($ageGroupId: ID!, $assessmentId: ID!, $playerAssessmentIds: [ID!]!, $sendNotifications: Boolean!) {
    concludePlayers(ageGroupId: $ageGroupId, assessmentId: $assessmentId, playerAssessmentIds: $playerAssessmentIds, sendNotifications: $sendNotifications) {
      _id
      team
    }
  }
`;

interface IPlayerRankingReportGraphQL {
  ageGroupId: string;
}

const PlayerRankingReportGraphQL: React.FC<IPlayerRankingReportGraphQL> = ({ ageGroupId }) => {
  const termLookup = getCompanySportTerminology();
  const athletesTerm = pluralTerm(termLookup[SportTerminology.ATHLETE].toLowerCase());

  const user = getAuthUser();
  const [assignLevelMutation] = useMutation(ASSIGN_LEVEL);
  const [assignTeamMutation] = useMutation(ASSIGN_TEAM);
  const [concludePlayersMutation] = useMutation(CONCLUDE_PLAYERS);
  const { data, error, loading } = useQuery(GET_PLAYER_RANKINGS, {
    variables: {
      assessmentId: user?.settings.activeAssessmentId,
      ageGroupId
    },
    fetchPolicy: 'cache-and-network'
  });

  const playerAssessments = (data && data.playerAssessments) || [];
  const ageGroup = (data && data.ageGroup);

  const handleAssignLevel = (playerAssessmentIds: string[], level: string) => {
    if (window.confirm(`Are you sure you want to send level assignments to ${playerAssessmentIds.length} ${athletesTerm}?`)) {
      assignLevelMutation({
        variables: {
          playerAssessmentIds,
          level,
          assessmentId: user?.settings.activeAssessmentId
        },
      });
    }
  }

  const handleAssignTeam = (playerAssessmentIds: string[], team: string) => {
    if (window.confirm(`Are you sure you want to send team assignments to ${playerAssessmentIds.length} ${athletesTerm}?`)) {
      assignTeamMutation({
        variables: {
          playerAssessmentIds,
          team,
          assessmentId: user?.settings.activeAssessmentId
        },
      });
    }
  }

  const handleConcludePlayers = (playerAssessmentIds: string[], sendNotifications: boolean) => {
    if (window.confirm(`Are you sure you want to conclude ${playerAssessmentIds.length} players? ${sendNotifications ? 'Notifications will be sent.' : 'Notifications will not be sent.'}`)) {
      concludePlayersMutation({
        variables: {
          playerAssessmentIds,
          sendNotifications,
          assessmentId: user?.settings.activeAssessmentId,
          ageGroupId
        },
      });
    }
  }

  return <PlayerRankingReportContainer
    ageGroup={ageGroup}
    playerAssessments={playerAssessments}
    loading={loading}
    fetchError={Boolean(error)}
    assignLevel={handleAssignLevel}
    assignTeam={handleAssignTeam}
    concludePlayers={handleConcludePlayers}
  />;
};

export default PlayerRankingReportGraphQL;
