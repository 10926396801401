import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ManageEvaluations from './manage-evaluations.component';
import { groupByPosition, sortByJersey } from './manage-evaluations.helpers';
import { IFormOption } from '../../_core/_ui/forms.component';
import { IRecordCompositeScoreInput, IRecordScoreInput, IRecordUserActionsResults } from './manage-evaluations.interface';
import { getQueueOpen } from '../../_core/PersistGateContainer/persist-gate.container';
import { getCompanySport } from '../../../lib/services/company.service';
import uuid from 'uuid';
import { getAuthUser } from '../../../lib/services/auth.service';
import { getAllCurrUsersSessions, recordUserSessionActions, removeUserSessionActions, transformCompScoreOperationToAction, transformScoreOperationToAction } from '../../../lib/utils/user-session.service';

interface IManageEvaluationsContainer {
  calibrationVideos: ICalibrationVideo[];
  assessmentSession?: IAssessmentSession;
  drills: IDrill[];
  players: IPlayerAssessment[];
  jerseys: { [key: string]: IJersey };
  scores: IScore[];
  compositeScores: ICompositeScore[];
  loading: boolean;
  fetchError: boolean;
  member?: IMember;
  submitScores: () => Promise<IRecordUserActionsResults | undefined>
  hasUnsavedScores: boolean;
  setHasUnsavedScores: Function;
  isSaving: boolean;
}

const ManageEvaluationsContainer: React.FC<IManageEvaluationsContainer> = ({
  calibrationVideos,
  assessmentSession,
  drills,
  players,
  jerseys,
  scores,
  compositeScores,
  loading,
  fetchError,
  member,
  submitScores,
  hasUnsavedScores,
  setHasUnsavedScores,
  isSaving
}) => {
  const user = getAuthUser()

  const isQueueOpen = getQueueOpen();
  const [showReview, setShowReview] = useState(false);
  const [scoreHash, setScoreHash] = useState({});
  const [compositeScoreHash, setCompositeScoreHash] = useState<{ [key: string]: { [key: string]: { [key: string]: { [key: number]: ICompositeScore } } } }>({});
  const [chosenVideos, setChosenVideos] = useState<{ [key: string]: { [key: string]: { [key: number]: ICalibrationVideo } } }>({});
  const [activeRound, setActiveRound] = useState(1);
  const [activeDrill, setActiveDrill] = useState<IDrill | undefined>();
  const [drillsById, setDrillsById] = useState<{ [key: string]: IDrill }>({});
  const [drillOptions, setDrillOptions] = useState<IFormOption[]>([]);
  const [activePlayer, setActivePlayer] = useState<IPlayerAssessment | undefined>();
  const [playersByPosition, setPlayersByPosition] = useState({});
  const [playerOptions, setPlayerOptions] = useState<IFormOption[]>([]);
  const [playersById, setPlayersById] = useState<{ [key: string]: IPlayerAssessment }>({});
  const [playersLinear, setPlayersLinear] = useState<IPlayerAssessment[]>([]);
  const [currPlayerIndex, setCurrPlayerIndex] = useState(0);
  const [stateLoaded, setStateLoaded] = useState(false);
  const [hasSaveError, setHasSaveError] = useState(false);
  const [isComposite, setIsComposite] = useState(false);
  const [activeComposite, setActiveComposite] = useState<number>();
  const [hasCalibrationVideos, setHasCalibrationVideos] = useState(false);
  const numJerseys = _.keys(jerseys).length;

  const sport = getCompanySport()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (players.length && numJerseys > 0) {
      const nextPlayersByPosition = groupByPosition(players, sport);
      _.each(nextPlayersByPosition, (players, position) => {
        nextPlayersByPosition[position] = sortByJersey(nextPlayersByPosition[position], jerseys);
      });

      const positions = _.keys(nextPlayersByPosition);
      const firstPlayer = _.get(nextPlayersByPosition, [positions[0], '0']);
      if (firstPlayer) {
        setActivePlayer(firstPlayer);
      }
      setPlayersByPosition(nextPlayersByPosition);
      setPlayersById(_.keyBy(players, '_id'))

      const nextPlayerOptions: any[] = [];
      _.each(nextPlayersByPosition, (players, position) => {
        nextPlayerOptions.push({
          label: position,
          options: _.map(players, player => ({ value: player._id, label: `${_.capitalize(jerseys[player._id]?.color)} - ${jerseys[player._id]?.number}`}))
        });
      });
      setPlayerOptions(nextPlayerOptions);

      setPlayersLinear(_.flatten(_.values(nextPlayersByPosition)));

      setStateLoaded(true);
    }
  }, [players.length, numJerseys]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const createScoreHash = async () => {
      const nextScoreHash = {};

      if (user) {
        // Create initial score hash
        _.each(scores, score => {
          _.set(nextScoreHash, [score.playerAssessmentId, score.drillId, score.skillId, score.round], score);
        });

        // Loop through all sessions and apply all score actions to scorehash
        const userSessions = await getAllCurrUsersSessions(user)
        userSessions?.forEach(s => {
          _.each(s.actions, action => {
            if (action.actionType === "score" || action.actionType === "removeScore") {
              const scoreAction = (action.payload as {score: IScore}).score

              // On score, find and updated latest score hash for target
              if (action.actionType === "score") {
                const currScore = _.get(nextScoreHash, [scoreAction.playerAssessmentId, scoreAction.drillId, scoreAction.skillId, scoreAction.round]) as IScore | undefined
                const updatedScore: IScore = {...scoreAction, _id: currScore?._id ?? scoreAction._id, clientId: currScore?.clientId ?? scoreAction.clientId}
                _.set(nextScoreHash, [scoreAction.playerAssessmentId, scoreAction.drillId, scoreAction.skillId, scoreAction.round], updatedScore);
              }
              // On remove, unset score
              else if (action.actionType === "removeScore")
                _.unset(nextScoreHash, [scoreAction.playerAssessmentId, scoreAction.drillId, scoreAction.skillId, scoreAction.round]);
            }
          })
        })

        setScoreHash(nextScoreHash);
      }
    }

    if (!loading) createScoreHash()
  }, [loading, scores, user]);

  useEffect(() => {
    const createCompositeScoreHash = async () => {
      const nextScoreHash = {};

      if (user) {
        // Create initial score hash
        _.each(compositeScores, score => {
          _.set(nextScoreHash, [score.compositeIndex, score.drillId, score.skillId, score.round], score);
        });

        // Loop through all sessions and apply all score actions to scorehash
        const userSessions = await getAllCurrUsersSessions(user)
        userSessions?.forEach(s => {
          _.each(s.actions, action => {
            if (action.actionType === "compositeScore" || action.actionType === "removeCompositeScore") {
              const scoreAction = (action.payload as {score: ICompositeScore}).score

              // On score, find and updated latest score hash for target
              if (action.actionType === "compositeScore") {
                const currScore = _.get(nextScoreHash, [scoreAction.compositeIndex, scoreAction.drillId, scoreAction.skillId, scoreAction.round]) as ICompositeScore | undefined
                const updatedScore: IScore = {...scoreAction, _id: currScore?._id ?? scoreAction._id, clientId: currScore?.clientId ?? scoreAction.clientId}
                _.set(nextScoreHash, [scoreAction.compositeIndex, scoreAction.drillId, scoreAction.skillId, scoreAction.round], updatedScore);
              }
              // On remove, unset score
              else if (action.actionType === "removeCompositeScore")
                _.unset(nextScoreHash, [scoreAction.compositeIndex, scoreAction.drillId, scoreAction.skillId, scoreAction.round]);
            }
          })
        })

        setCompositeScoreHash(nextScoreHash);
      }
    }

    if (!loading) createCompositeScoreHash()
  }, [loading, compositeScores, user]);

  const handleSetActiveDrill = useCallback((drill: IDrill) => {
    setActiveDrill(drill);
    const hasDrillVideos = _.filter(calibrationVideos, video => video.drillId === drill._id).length > 0;

    setHasCalibrationVideos(hasDrillVideos);
    if (!hasDrillVideos) setIsComposite(false);
  }, [calibrationVideos]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (drills.length) {
      const nextDrillOptions: IFormOption[] = drills.map(drill => ({
        value: drill._id,
        label: drill.name
      }));

      setDrillsById(_.keyBy(drills, '_id'));
      setDrillOptions(nextDrillOptions);
      handleSetActiveDrill(drills[0]);
    }
  }, [assessmentSession, drills.length, handleSetActiveDrill]);

  const setChosenVideo = (activeComposite: number, drillId: string, round: number, video: ICalibrationVideo) => {
    const nextChosenVideos = { ...chosenVideos };

    _.set(nextChosenVideos, [String(activeComposite), drillId, round], video);

    setChosenVideos(nextChosenVideos);
  };

  const handleGetCompositeScoreStatus = useCallback((compositeIndex: number, drillId: string) => {
    let currSkillCount = 0;

    const relevantVideos = calibrationVideos.filter((video) => video.drillId === drillId);
    const relevantSkills = relevantVideos.map((video) => video.skill);
    const expectedSkillCount = _.uniqBy(relevantSkills, '_id').length;

    const compositeScores = _.get(compositeScoreHash, [compositeIndex, drillId], {});
    _.each(compositeScores, (roundScores) => {
      if (roundScores && roundScores[activeRound]) {
        currSkillCount += 1;
      }
    });

    if (currSkillCount === expectedSkillCount) return 'FILLED';
    if (currSkillCount > 0) return 'PARTIAL';
    return 'NONE';
  }, [activeRound, calibrationVideos, compositeScoreHash]);

  const handleGetScoreStatus = useCallback((playerAssessment: IPlayerAssessment, drillId: string) => {
    let expectedSkillCount = 0;
    const activeSkillIds: string[] = []
    _.each(drillsById[drillId].skillSets, (skillSet => {
      if (_.includes(skillSet.positionIds, playerAssessment.position._id)) {
        _.each(skillSet.skills, skill => {
          if (!skill.deletedAt) {
            expectedSkillCount += 1;
            activeSkillIds.push(skill._id)
          }
        })
      }
    }))
    const skillScores = _.get(scoreHash, [playerAssessment._id, drillId], {});
    let currSkillCount = 0;

    _.each(skillScores, (roundScores, skillId): void => {
      if (roundScores && roundScores[activeRound] && activeSkillIds.includes(skillId)) {
        currSkillCount += 1;
      }
    });

    if (currSkillCount === expectedSkillCount) return 'FILLED';
    if (currSkillCount > 0) return 'PARTIAL';
    return 'NONE';
  }, [activeRound, drillsById, scoreHash]);

  const handleSetActiveComposite = (index: number) => {
    setIsComposite(true);
    setActiveComposite(index);
  };

  const handleSetActivePlayer = (player: IPlayerAssessment) => {
    setIsComposite(false);
    setCurrPlayerIndex(_.findIndex(playersLinear, currPlayer => currPlayer._id === player._id));
    setActivePlayer(player);
  }

  /*
   * Score operations are stored in lists in created order.
   * These operations are polled at intervals (or on demand), and are submitted as score actions to save/store for later upload.
   *
   * Scores are still stored for UX evaluation purposes in a score hash. So score updates should be performed against both fields.
   */
  const handleRemoveCompositeScore = (score: IRecordCompositeScoreInput) => {
    const nextScoreHash = { ...compositeScoreHash };
    _.unset(nextScoreHash, [score.compositeIndex, score.drillId, score.skillId, score.round]);
    setCompositeScoreHash(nextScoreHash);

    const scoreOp = {..._.omit(score, ['__typename']), loggedAt: new Date(), remove: true}
    if (user) recordUserSessionActions(user, [transformCompScoreOperationToAction(scoreOp)])
    setHasUnsavedScores(true);
  };

  const handleRecordCompositeScore = (score: IRecordCompositeScoreInput) => {
    const loggedAt = new Date();
    const clientId = score.clientId ?? loggedAt.getTime() + "-" + user?._id + "-" + uuid.v4();

    const nextScoreHash = { ...compositeScoreHash };
    _.set(nextScoreHash, [score.compositeIndex, score.drillId, score.skillId, score.round], {...score, loggedAt, clientId});
    setCompositeScoreHash(nextScoreHash);

    const scoreOp = {..._.omit(score, ['__typename']), loggedAt, clientId}
    if (user) recordUserSessionActions(user, [transformCompScoreOperationToAction(scoreOp)])
    setHasUnsavedScores(true);

  };

  const handleRemoveScore = (score: IRecordScoreInput) => {
    const nextScoreHash = { ...scoreHash };
    _.unset(nextScoreHash, [score.playerAssessmentId, score.drillId, score.skillId, score.round]);
    setScoreHash(nextScoreHash);

    const scoreOp = {..._.omit(score, ['__typename']), loggedAt: new Date(), remove: true}
    if (user) recordUserSessionActions(user, [transformScoreOperationToAction(scoreOp)])
    setHasUnsavedScores(true);
  };

  const handleRecordScore = (score: IRecordScoreInput) => {
    const loggedAt = new Date();
    const clientId = score.clientId ?? loggedAt.getTime() + "-" + user?._id + "-" + uuid.v4();

    const nextScoreHash = { ...scoreHash };
    _.set(nextScoreHash, [score.playerAssessmentId, score.drillId, score.skillId, score.round], {...score, loggedAt, clientId});
    setScoreHash(nextScoreHash);

    const scoreOp = {..._.omit(score, ['__typename']), loggedAt, clientId}
    if (user) recordUserSessionActions(user, [transformScoreOperationToAction(scoreOp)])
    setHasUnsavedScores(true);
  }

  const handleSubmitScores = useCallback(() => {
    if (isSaving) return;

    setHasUnsavedScores(false)
    return submitScores()
      .then(async (results) => {
        let failed = false
        if (results) {
          // Receive results of each score operation in latest submit, and then filter out successful score operations from local storage
          const actionsToRemove: IUserAction[] = []
          results.recordUserSession?.forEach((r, i) => {
            if (r?.succeeded) {
              const matches = results.userSession.actions.filter((sa) => new Date(sa.loggedAt).getTime() === new Date(r.loggedAt).getTime())
              actionsToRemove.push(...matches)
            } else {
              failed = true
            }
          })

          removeUserSessionActions(results.user._id, results.userSession, actionsToRemove)
        } else {
          failed = true
        }
 
        setHasSaveError(failed);

        if (failed) setTimeout(() => {
            setHasSaveError(false);
          }, 3000);
      })
      .catch(() => {
        setHasUnsavedScores(true);

        setHasSaveError(true);
        setTimeout(() => {
          setHasSaveError(false);
        }, 3000);
      });
  }, [isSaving])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (hasUnsavedScores && !isSaving && assessmentSession && user) {
        const storeScores = async () => {
          if (hasUnsavedScores && !isSaving) {
            setHasUnsavedScores(false)
            if (isQueueOpen) {
              handleSubmitScores()
            }
          }
        }

        storeScores();
      }
    }, 5 * 1000);
    return () => clearInterval(interval);
  }, [hasUnsavedScores, isSaving, handleSubmitScores]);

  const canCheckIn = (member && _.includes(member.roles, 'CHECK_IN')) || false;

  return <ManageEvaluations
    calibrationVideos={calibrationVideos}
    loading={loading || !stateLoaded}
    fetchError={fetchError}
    session={assessmentSession}
    jerseys={jerseys}
    removeScore={handleRemoveScore}
    recordScore={handleRecordScore}
    submitScores={handleSubmitScores}
    playersByPosition={playersByPosition}
    playersById={playersById}
    playersLinear={playersLinear}
    currPlayerIndex={currPlayerIndex}
    setCurrPlayerIndex={setCurrPlayerIndex}
    playerOptions={playerOptions}
    drills={drills}
    drillsById={drillsById}
    drillOptions={drillOptions}
    hasCalibrationVideos={hasCalibrationVideos}
    activePlayer={activePlayer}
    isComposite={isComposite}
    activeComposite={activeComposite}
    setActiveComposite={handleSetActiveComposite}
    setActivePlayer={handleSetActivePlayer}
    activeDrill={activeDrill}
    setActiveDrill={handleSetActiveDrill}
    activeRound={activeRound}
    setActiveRound={setActiveRound}
    getScoreStatus={handleGetScoreStatus}
    getCompositeScoreStatus={handleGetCompositeScoreStatus}
    scoreHash={scoreHash}
    compositeScoreHash={compositeScoreHash}
    showReview={showReview}
    setShowReview={setShowReview}
    canCheckIn={canCheckIn}
    hasUnsavedScores={hasUnsavedScores}
    hasSaveError={hasSaveError}
    isSaving={isSaving}
    setChosenVideo={setChosenVideo}
    chosenVideos={chosenVideos}
    recordCompositeScore={handleRecordCompositeScore}
    removeCompositeScore={handleRemoveCompositeScore}
  />
};

export default ManageEvaluationsContainer;
