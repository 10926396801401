import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';

// Components
import ManageEvaluationsContainer from './manage-evaluations.container';
import { getCurrMember } from '../../../lib/services/member.service';
import {
  getIsSavingScores,
  getShouldRefetchAfterOfflineSync,
  isSavingScores,
  shouldRefetchAfterOfflineSync,
} from '../../_core/OfflineHandler/offline-handler.container';
import { isWeb, videoSrc } from '../../../lib/utils/video.utils';
import { companyHasCompositeVideos } from '../../../lib/services/company.service';
import { getCurrUserSession, reduceUserActions,   } from '../../../lib/utils/user-session.service';
import { getAuthUser } from '../../../lib/services/auth.service';

// Data
const GET_MANAGE_EVALUATIONS = gql`
query GetManageEvaluations($assessmentSessionId: ID!) {
  calibrationVideos(assessmentSessionId : $assessmentSessionId) {
    _id
    filename
    drillId
    skillId
    skill {
      _id
      name
      type
      options
    }
  }
  assessmentSession(_id: $assessmentSessionId) {
    _id
    location
    area
    address
    date
    start
    assessmentId
    ageGroup {
      _id
      name
    }
    practicePlans {
      drills {
        _id
        name
        image
        deletedAt
        skillSets {
          positionIds
          skills {
            _id
            name
            type
            options
            deletedAt
          }
        }
      }
    }
    drills {
      _id
      name
      image
      deletedAt
      skillSets {
        positionIds
        skills {
          _id
          name
          type
          options
          deletedAt
        }
      }
    }
    checkedInPlayers {
      _id
      position {
        _id
        name
      }
      ageGroup {
        _id
      }
      player {
        firstName
        lastName
      }
    }
    jerseys
  }
}
`;

const GET_MANAGE_EVALUATION_SCORES = gql`
  query GetManageEvaluationScores($assessmentSessionId: ID!) {
    evaluationCompositeScores(assessmentSessionId: $assessmentSessionId) {
      _id
      raw
      type
      compositeIndex
      calibrationVideoId
      drillId
      skillId
      round
      assessmentId
      ageGroupId
      assessmentSessionId
    }
    evaluationScores(assessmentSessionId: $assessmentSessionId) {
      _id
      raw
      type
      playerAssessmentId
      drillId
      skillId
      assessmentId
      ageGroupId
      assessmentSessionId
      round
    }
  }
`;

export const RECORD_USER_SESSION_ACTION = gql`
  mutation RecordUserSession($startedAt: Date!, $actions: JSONObject!, $newUserId: ID, $skipDuplicateActions: Boolean) {
    recordUserSession(startedAt: $startedAt, actions: $actions, newUserId: $newUserId, skipDuplicateActions: $skipDuplicateActions) {
      succeeded
      scoreId
      loggedAt
    }
  }
`;

interface IManageEvaluationsGraphQL {
  assessmentSessionId: string;
}

const ManageEvaluationsGraphQL: React.FC<IManageEvaluationsGraphQL> = ({ assessmentSessionId }) => {
  const [calibrationVideos, setCalibrationVideos] = useState<ICalibrationVideo[]>([]);
  const [hasUnsavedScores, setHasUnsavedScores] = useState(false);
  const isSaving = getIsSavingScores();
  const hasCompositeVideos = companyHasCompositeVideos();
  const user = getAuthUser();
  const { member } = getCurrMember();
  const { data, loading: loadingEvaluations, error: evaluationsError, ...restEvaluations } = useQuery(GET_MANAGE_EVALUATIONS, {
    variables: { assessmentSessionId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network'
  });

  const { data: scoresData, loading: loadingScores, error: scoresError, called: scoresCalled, ...restScores } = useQuery(GET_MANAGE_EVALUATION_SCORES, {
    variables: { assessmentSessionId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network'
  });

  const tryRequireVideo = async (name: string) => {
    try {
      if (isWeb)
        return !!(await fetch(`${videoSrc}/${name}`, {
          method: "HEAD",
        })).ok;

      return !!(require(`../../../../../public/videos/${name}`));
    } catch (err) {
     return false;
    }
  };

  useEffect(() => {
    if (data?.calibrationVideos && hasCompositeVideos) {
      const videos: ICalibrationVideo[] = data.calibrationVideos;

      // Determine if all videos exist, perform check once per unique video
      const promiseIds: Record<string, number> = {}
      const promises: Promise<boolean>[] = []
      videos.map(v => {
        if (!(v.filename in promiseIds)) {
          promiseIds[v.filename] = promises.length
          promises.push(tryRequireVideo(v.filename))
        }
      })

      // Resolve all promises and determine which videos actually exist
      Promise
        .all(promises)
        .then((videoExists) => setCalibrationVideos(videos.filter(({filename}, i) => videoExists[promiseIds[filename]])))
    }
  }, [data?.calibrationVideos]);

  const assessmentSession = data && data.assessmentSession;
  const players = (data && data.assessmentSession.checkedInPlayers) || [];
  const jerseys = (data && data.assessmentSession.jerseys) || {};
  const scores = (scoresData && scoresData.evaluationScores) || [];
  const compositeScores = (scoresData && scoresData.evaluationCompositeScores) || [];
  let drills = (data && data.assessmentSession.drills) || [];
  let practicePlans = (data && data.assessmentSession.practicePlans) || [];

  practicePlans.forEach((plan: IPracticePlan) => {
    drills = drills.concat(plan.drills || []);
  });
  drills = drills.filter((d: IDrill) => !d.deletedAt)
  drills = _.uniqBy(drills, '_id');

  const [recordUserSessionActionMutation] = useMutation(RECORD_USER_SESSION_ACTION);

  const shouldRefetch = getShouldRefetchAfterOfflineSync()
  useEffect(() => {
    // Wait until scores have finished fetching once to refetch
    if (shouldRefetch && scoresCalled && !loadingScores) {
      shouldRefetchAfterOfflineSync(false)
      restScores.refetch()
    }
  }, [shouldRefetch, scoresCalled, restScores, loadingScores])

  const handleSubmitScores = async () => {
    if (!isSaving && user) {
      isSavingScores(true);

      try {
        const userSession = await getCurrUserSession(user)
        if (userSession) {
          const results = await recordUserSessionActionMutation({
            variables: {
              userId: user._id,
              startedAt: userSession.startedAt,
              actions: reduceUserActions(userSession.actions),
              skipDuplicateActions: true
            }
          })

          restScores.refetch()
          return {user, userSession, recordUserSession: results.data.recordUserSession}
        }
      } catch (err) {
        console.error("Failed submitting scores", err)
      } finally {
        isSavingScores(false)
      }
    }
  };

  return <ManageEvaluationsContainer
    calibrationVideos={calibrationVideos}
    assessmentSession={assessmentSession}
    drills={drills}
    players={players}
    jerseys={jerseys}
    scores={scores}
    compositeScores={compositeScores}
    member={member}
    submitScores={handleSubmitScores}
    loading={loadingEvaluations || loadingScores}
    fetchError={!!evaluationsError || !!scoresError}
    hasUnsavedScores={hasUnsavedScores}
    setHasUnsavedScores={setHasUnsavedScores}
    isSaving={isSaving}
  />;
};

export default ManageEvaluationsGraphQL;
